




import {
  defineComponent,
} from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'ElementText',
  props: {
    element: {
      type: Object,
      require: true
    }
  }
});
